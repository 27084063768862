




































import { defineComponent } from '@vue/composition-api';
import Table from '@/features/ui/table/Table.global.vue';
import TableSkeleton from '@/features/ui/table/TableSkeleton.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    TableSkeleton,
    Table,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    auditLogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        { name: 'id', label: 'Liegenschafts ID' }, // Liegenschafts ID
        { name: 'pdtpNumber', label: 'PDTP/CCR-Nummer' }, // PDTP/CCR-Nummer
        { name: 'address', label: 'Liegenschaft' }, // Object name (Address), including post code and city
        { name: 'section', label: 'Anschrift' }, // Heading of the section of the New Project form. E.g. Projektdaten ; Produkte ; etc.
        { name: 'fieldEdited', label: 'Feld Bearbeitet' }, // Heading of the field edited. E.g. Kundennummer ; Main Service ; etc.
        { name: 'previousValue', label: 'Vorheriger Wert' }, // Value in the field before the edit was made.
        { name: 'updatedValue', label: 'Aktualisierter Wert' }, // Value in the field after the edit was made.
        { name: 'userName', label: 'Benutzer' }, // User name of the GK4.0 user who made the edit.
        { name: 'timestamp', label: 'Zeitstempel' }, // Timestamp when the edit was saved.
      ],
    };
  },
  computed: {},
  methods: {},
});
