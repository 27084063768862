




















import { defineComponent } from '@vue/composition-api';
import Table from '@/features/ui/table/Table.global.vue';
import TableSkeleton from '@/features/ui/table/TableSkeleton.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    TableSkeleton,
    Table,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    auditLogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        { name: 'projectReference', label: 'Project ID' },
        { name: 'username', label: 'Benutzer' }, // User name of the GK4.0 user who made the edit.
        { name: 'timestamp', label: 'Zeitstempel' }, // Timestamp when the edit was saved.
      ],
    };
  },
  computed: {},
  methods: {},
});
