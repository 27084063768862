






































































































import { defineComponent, ref } from '@vue/composition-api';
import TabBar from '@/features/ui/tab-bar/TabBar.global.vue';
import TabButton from '@/features/ui/tab-bar/TabButton.global.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import ProjectCustomerForm from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectCustomerForm.vue';
import ProjectBuildingImporter from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectBuildingImporter.vue';
import ProjectBuildingInformation from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectBuildingInformation.vue';
import { mapActions, mapState } from 'vuex';
import PropertyList from '@/features/app-enterprise-project/views/enterprise-project-create/components/step2/PropertyList.vue';
import PropertyBuildingAssociation from '@/features/app-enterprise-project/views/enterprise-project-create/components/step2/PropertyBuildingAssociation.vue';
import ProjectCreateProductsAndPrices from '@/features/app-enterprise-project/views/enterprise-project-create/components/step3/ProjectCreateProductsAndPrices.vue';
import {
  GetProjectDataUsingIDQuery,
  GetProjectDataUsingIDQueryVariables,
} from './__generated__/GetProjectDataUsingIDQuery';
import {
  GetProjectEditableBuildingsQuery,
  GetProjectEditableBuildingsQueryVariables,
} from './__generated__/GetProjectEditableBuildingsQuery';
import getEditableProjectBuildings from './editable-project-buildings.gql';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import getProjectData from './get-project-draft.gql';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import Spinner from '@/components/clickables/Spinner.vue';
import ProjectBuildingInformationSelect from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectBuildingInformationSelect.vue';
import { CreatedBuildingMeta } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import EnterpriseProjectPreview from '@/features/app-enterprise-project/views/enterprise-project-create/components/EnterpriseProjectPreview.vue';
import { EditOptions } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';

interface EnterpriseProjectCreateProps {
  projectReference: string;
  buildingChangeMode?: boolean;
}

interface EnterpriseProjectCreateData {
  step: number;
}

export default defineComponent<
  EnterpriseProjectCreateProps,
  Record<string, unknown>,
  EnterpriseProjectCreateData,
  any,
  any
>({
  components: {
    EnterpriseProjectPreview,
    ProjectBuildingInformationSelect,
    WizardButton,
    PropertyBuildingAssociation,
    PropertyList,
    ProjectBuildingInformation,
    ProjectBuildingImporter,
    ProjectCustomerForm,
    Cell,
    Panel,
    TabBar,
    TabButton,
    ProjectCreateProductsAndPrices,
    Spinner,
  },
  props: {
    projectReference: {
      type: String,
      required: false,
      default: null,
    },
    buildingChangeMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectEditOptions: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
      // Clear the state
      emit('clear');
    };

    return {
      visible,
      modal,
      show,
      hide,
    };
  },
  data() {
    return {
      step: 1,
    };
  },
  computed: {
    ...mapState({
      generalInformationCompleted: (state: any) => state.EnterpriseProjectCreateModule.generalInformationCompleted,
      buildingsImportCompleted: (state: any) => state.EnterpriseProjectCreateModule.buildingsImportCompleted,
      propertyInformationCompleted: (state: any) => state.EnterpriseProjectCreateModule.propertyInformationCompleted,
      propertyAssociationCompleted: (state: any) => state.EnterpriseProjectCreateModule.propertyAssociationCompleted,
      displayPropertyInformationCheckboxes: (state: any) =>
        state.EnterpriseProjectCreateModule.displayPropertyInformationCheckboxes,
      newProjectData: (state: any) => state.EnterpriseProjectCreateModule.newProjectData,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep1BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep1BuildingId,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      tab: (state: any) => state.EnterpriseProjectCreateModule.tab,
      saving: (state: any) => state.EnterpriseProjectCreateModule.saving,
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: ProjectBuilding) =>
              building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
          );
        }
      },
      // Boolean for if all L buildings has information
      allLiegenshaftInformationCompleted(state: any) {
        return state.EnterpriseProjectCreateModule.allLiegenshaftInformationCompleted;
      },
    }),
    numberOfProperties() {
      let count = 0;
      if (this.buildings) {
        this.buildings.forEach((building: ProjectBuilding) => {
          if (building.liegenshaft) {
            count += 1;
          }
        });
      }
      return count;
    },
  },
  async mounted() {
    if (this.projectReference) {
      await this.setUpdateTab('step1');
      // Make a GraphQL query to populate the state with the project data
      const { data } = await this.$apollo.query({
        query: getProjectData,
        variables: {
          projectReference: this.projectReference,
        } as GetProjectDataUsingIDQueryVariables,
      });
      const { projectDraft } = data as GetProjectDataUsingIDQuery;
      if (!projectDraft) {
        return;
      }

      const buildingChangeMode = this.buildingChangeMode;
      let editableBuildings = new Map<string, CreatedBuildingMeta>();

      if (buildingChangeMode) {
        const { data } = await this.$apollo.query({
          query: getEditableProjectBuildings,
          variables: {
            projectReference: this.projectReference,
          } as GetProjectEditableBuildingsQueryVariables,
        });

        const { project } = data as GetProjectEditableBuildingsQuery;
        editableBuildings = this.constructEditableBuildingMap(project);
      }

      const projectEditOptions = this.projectEditOptions;
      const editOptions: EditOptions = { ...projectEditOptions, buildingChangeMode, editableBuildings };

      await this.hydrateProjectDraftData({ ...projectDraft.pageContent, ...editOptions });
    } else {
      // If there is no project reference, clear the state
      this.$store.commit('clearState');
      await this.setUpdateTab('step1');
    }
    await this.$store.dispatch('getNextActiveBuilding');
  },
  methods: {
    ...mapActions(['setUpdateTab', 'hydrateProjectDraftData', 'saveToDB', 'setPropertyAssociationCompleted']),
    goToProjectList() {
      this.$router.push('/enterprise-project/project-list');
    },
    // Tab
    updateTab(tab: string) {
      this.setUpdateTab(tab);
    },
    goToStep(step: string) {
      this.$store.dispatch('setUpdateTab', step);
      // Save if we go to step 3
      if (step === 'step3') {
        this.$store.dispatch('setPropertyAssociationCompleted', true);
      }
      if (step !== 'step1') {
        this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
      }
    },
    // Step buttons
    getNextActiveStep() {
      this.$store.dispatch('getNextActiveBuilding');
      this.$store.dispatch('setBuildingsImportCompleted', true);
      this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
    },
    constructEditableBuildingMap(project: GetProjectEditableBuildingsQuery['project']) {
      const editableBuildings = new Map<string, CreatedBuildingMeta>();
      if (!project?.editableBuildings) {
        return editableBuildings;
      }

      for (const building of project.editableBuildings) {
        for (const buildingId of building.draftBuildingIds) {
          editableBuildings.set(buildingId, { buildingReferenceId: building.buildingReferenceId });
        }
      }

      return editableBuildings;
    },
  },
});
