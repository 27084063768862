import { EnterpriseProjectObjectUseCase } from '@/features/app-enterprise-project/enterprise-project-constants';
import { EnterpriseProjectObjectProduct, EnterpriseProjectObjectProductOption } from '@/types/iot-portal';
import { Alignment, Borders, Fill, Font, Style } from 'exceljs';

export const HEADER_ROW = [
  '',
  'Referenz-nummer Liegen-schaft',
  'Produktnutzungsgruppe',
  'Gateway',
  '',
  '',
  '',
  'Liegenschaft-Adresse (Hauptgebäude)',
  'Gebäude-Adresse',
  'Infra-struktur-variante',
  'optional Wirtschafts-einheit (WIE) der Liegenschaft',
  'Anzahl Wohn-ein-heiten',
  'Anspr.partner Vor-Ort  / Technische/r Ansprechpartner/in',
  '',
  '',
  'Wunschtermin/ Liegenschaft',
  'optional Rechnungstext',
  'IW Cloud Pro oder Standard',
  '',
  '',
  'Service Level',
  'optional Ortsbegehung',
  '',
  'Digitalisierung TGA (Montage/Material) (DTGA)',
  '',
  'Heizungs-monitoring (HzM)',
  '',
  '',
  'Heizungs-profi (HzP)',
  '',
  '',
  'Zählerfern-auslesung (ZFA)',
  '',
  '',
  'TGA Monitoring (TGAM)',
  '',
  '',
  'TGA Integration (TGAI)',
  '',
  '',
  'Wettbewerbliche Messstellen-betrieb (SMG)',
  '',
  '',
  'Dienstleistungs-management (DLM)',
  '',
  '',
  'Verbrauchs-datenerfassung (VDE)',
  '',
  '',
  'Unterjährige Verbrauchsinformation (UVI)',
  '',
  '',
  'Rauchwarn-melder-monitoring (RWM)',
  '',
  '',
  'Aufzugs-monitoring (AzM)',
  '',
  '',
  'Smarte Aufzugswartung (SAM)',
];

export const HEADER_ROW_2 = [
  '',
  '',
  'Adresse der Liegenschaft',
  'Main Service',
  'GW ID (L oder G)',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  'Lokation',
  'Lokation',
  '',
  '',
  '',
  'Name',
  'Telefonnummer',
  'eMail',
  '',
  '',
  'Typ',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  '',
  'Einmal-preis in EUR',
  '',
  'Einmal-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
];

export const BICAB_HEADER_ROW = [
  '',
  'Referenznummer Liegenschaft',
  'Produktnutzungsgruppe',
  'Adresse des Gebäudes, in dem der Access liegt',
  'Anbindung',
  'Bandbreite',
  '',
  '',
  'CPE',
  'SL',
  'Lagebeschreibung',
  'Bemerkung an Techniker',
];

export const BICAB_HEADER_ROW_2 = [
  '',
  '',
  '',
  '',
  'Kabel',
  'Mbit/s',
  'Einmal-preis in EUR',
  'Monats-preis in EUR',
  '',
  '',
  '',
  '',
];

export const HEADER_FILL: Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD0D0D0' } };
export const HEADER_FONT: Partial<Font> = { bold: true, size: 11, name: 'Arial Narrow', family: 2 };
export const HEADER_2_FONT: Partial<Font> = { bold: true, size: 10, name: 'Arial Narrow', family: 2 };
export const HEADER_BORDER: Partial<Borders> = {
  top: { style: 'thin', color: { argb: '00000000' } },
  left: { style: 'thin', color: { argb: '000000' } },
  bottom: { style: 'thin', color: { argb: '000000' } },
  right: { style: 'thin', color: { argb: '000000' } },
};
export const HEADER_ALIGNMENT: Partial<Alignment> = { vertical: 'middle', horizontal: 'center', wrapText: true };

export const HEADER_STYLE: Partial<Style> = {
  fill: HEADER_FILL,
  font: HEADER_FONT,
  border: HEADER_BORDER,
  alignment: HEADER_ALIGNMENT,
};

export const HEADER_2_STYLE: Partial<Style> = {
  fill: HEADER_FILL,
  font: HEADER_2_FONT,
  border: HEADER_BORDER,
  alignment: HEADER_ALIGNMENT,
};

export const ENTERPRISE_PRODUCT_OPTION_MAP: Record<EnterpriseProjectObjectProductOption, string> = {
  [EnterpriseProjectObjectProductOption.STANDARD]: 'Standard',
  [EnterpriseProjectObjectProductOption.PRO]: 'Pro',
  [EnterpriseProjectObjectProductOption.START]: 'Start',
  [EnterpriseProjectObjectProductOption.BASIS]: 'Basis',
  [EnterpriseProjectObjectProductOption.BASIS_PLUS]: 'Basis+',
};

export const LIEGENSCHAFT_GW_REXEP = /_L$/;

export const PRODUCT_COLUMN_NAME_MAP: Map<EnterpriseProjectObjectProduct, string[]> = new Map<
  EnterpriseProjectObjectProduct,
  string[]
>([
  [EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT, ['J']],
  [EnterpriseProjectObjectProduct.IW_CLOUD, ['R', 'S', 'T']],
]);

export const USE_CASE_COLUMN_NAME_MAP: Map<EnterpriseProjectObjectUseCase, string[]> = new Map<
  EnterpriseProjectObjectUseCase,
  string[]
>([
  [EnterpriseProjectObjectUseCase.TGA_DIGITIZATION, ['X', 'Y']],
  [EnterpriseProjectObjectUseCase.HEATING_MONITORING, ['Z', 'AA', 'AB']],
  [EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL, ['AC', 'AD', 'AE']],
  [EnterpriseProjectObjectUseCase.REMOTE_METER_READING, ['AF', 'AG', 'AH']],
  [EnterpriseProjectObjectUseCase.TGA_MONITORING, ['AI', 'AJ', 'AK']],
  [EnterpriseProjectObjectUseCase.TGA_INTEGRATION, ['AL', 'AM', 'AN']],
  [EnterpriseProjectObjectUseCase.SMG, ['AO', 'AP', 'AQ']],
  [EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT, ['AR', 'AS', 'AT']],
  [EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION, ['AU', 'AV', 'AW']],
  [EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR, ['AX', 'AY', 'AZ']],
  [EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING, ['BA', 'BB', 'BC']],
  [EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING, ['BD', 'BE', 'BF']],
  [EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE, ['BG', 'BH', 'BI']],
]);
