

























































































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Option } from '@/features/ui/inputs/model';
import { AppEnterpriseProjectCreateEnterpriseProjectVariables } from './__generated__/AppEnterpriseProjectCreateEnterpriseProject';
import query from './view.gql';
import customerQuery from './customer-by-external-id.gql';
import createProjectMutation from './create-project.gql';
import { EnterpriseProjectStatus } from '@/types/iot-portal';
import { defineComponent, ref } from '@vue/composition-api';
import { ProjectInfo } from './imports/models';
import { DEFAULT_PROJECT_SERVICE_PROVIDER } from '@/env';
import GroupsMixin from '@/features/core/components/mixins/groups';
import EnterpriseProjectCreateModal from '@/features/app-enterprise-project/views/enterprise-project-create/EnterpriseProjectCreateModal.vue';
import EnterpriseProjectsTable from '@/features/app-enterprise-project/views/enterprise-project/components/EnterpriseProjectsTable.vue';
import EnterpriseIcon from '../../assets/app-enterprise-project-alternative.svg?vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import TableSkeleton from '@/features/ui/table/TableSkeleton.global.vue';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import { GetProjectListQueryVariables } from '@/features/app-enterprise-project/views/enterprise-project/__generated__/GetProjectListQuery';

interface Upload {
  file: File;
  label: string;
}

interface CustomerInfo {
  id: string;
  name: string;
}

interface CreateProjectResult {
  projectReference: string;
}

export default defineComponent<Record<string, unknown>, any, any>({
  name: 'EnterpriseProjectsView',
  components: {
    EnterpriseProjectCreateModal,
    EnterpriseProjectsTable,
    EnterpriseIcon,
    Flex,
    Cell,
    Form,
    Panel,
    TableSkeleton,
    Pagination,
  },
  mixins: [PaginationQueryStringMixin, GroupsMixin],
  inject: ['xlsxImporter', 'objectListMapper'],
  apollo: {
    projectList: {
      query,
      fetchPolicy: 'network-only',
      variables() {
        return {
          search: this.querySearchParameter.length < 3 ? undefined : this.querySearchParameter,
          salesContact:
            this.querySalesPersonSearchParameter.length < 3 ? undefined : this.querySalesPersonSearchParameter,
          skip: this.skip,
          take: this.take,
          statuses: this.statuses,
        } as GetProjectListQueryVariables;
      },
    },
  },
  setup() {
    // This is for the modal
    const projectCreateModal = ref(null);

    const openModal = (): void => {
      if (!projectCreateModal.value) {
        return;
      }
      (projectCreateModal.value as any).show();
    };

    return {
      projectCreateModal,
      openModal,
    };
  },
  data() {
    return {
      projectList: undefined,
      customerId: undefined,
      // Filtering
      search: '', // Search input
      querySearchParameter: (this.$route.query.search as string) || '', // Initialize search from URL
      salesPersonSearch: '',
      querySalesPersonSearchParameter: (this.$route.query.salesPersonSearch as string) || '', // Initialize search from URL
      debounceTimer: null, // Timer for the debounce function
      statuses: ['PLANNED'],
    };
  },
  computed: {
    loading(): boolean {
      return this.$apollo.queries.projectList?.loading ?? true;
    },
    take(): number {
      return 14;
    },
    totalPages(): number {
      const pageCount = Math.ceil((this.projectList?.count ?? 0) / this.take);

      return pageCount;
    },
    skip(): number {
      return (this.currentPage - 1) * this.take;
    },
  },
  mounted() {
    this.$store.commit('clearState');
    // Set the search and salesPersonSearch parameters from the URL on component creation
    if (this.$route.query.search) {
      this.querySearchParameter = this.$route.query.search;
      this.search = this.$route.query.search;
    }
    if (this.$route.query.salesPersonSearch) {
      this.querySalesPersonSearchParameter = this.$route.query.salesPersonSearch;
      this.salesPersonSearch = this.$route.query.salesPersonSearch;
    }
  },
  methods: {
    onFilterInput() {
      // Clear the previous timer if the user is still typing
      clearTimeout(this.debounceTimer);

      // Set a new timer to update the query after 1000ms (or your desired delay)
      this.debounceTimer = setTimeout(() => {
        if (this.loading) {
          return;
        }
        this.querySearchParameter = this.search;
        this.querySalesPersonSearchParameter = this.salesPersonSearch;
        this.currentPage = 1;
        this.updateSearchQuery();
      }, 1000); // 1000ms delay
    },
    updateSearchQuery() {
      // Define the type for the query object
      const query: { search?: string; salesPersonSearch?: string } = {};

      // Add non-empty fields to the query object
      if (this.querySearchParameter) query.search = this.querySearchParameter;
      if (this.querySalesPersonSearchParameter) query.salesPersonSearch = this.querySalesPersonSearchParameter;

      // Update the URL with the query parameters
      this.$router.push({ query });
    },
    onPaginationUpdate(pageNumber: number): void {
      this.currentPage = pageNumber;
    },
    async getCustomerInfo(externalId: string): Promise<CustomerInfo | null> {
      try {
        const { data } = await this.$apollo.query({
          query: customerQuery,
          variables: {
            externalId,
          },
        });

        return { name: data.customers.first?.name, id: data.customers.first?.id };
      } catch (error) {
        console.error('An error occured while fetching customer info from the file import:', error);
        return null;
      }
    },
    async createProject(
      payload: AppEnterpriseProjectCreateEnterpriseProjectVariables,
    ): Promise<CreateProjectResult | null> {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: createProjectMutation,
          variables: {
            input: payload.input,
          },
        });

        this.refetchQueries();

        return { projectReference: data.createProject.project.projectReference };
      } catch (error) {
        console.error('An error occured while importing the project:', error);
        return null;
      }
    },
    async uploadProjectFile({ file, label }: Upload): Promise<void> {
      const buffer = await (file as File).arrayBuffer();

      const projectInfo: ProjectInfo = this.xlsxImporter.import(buffer);

      const objectList = this.objectListMapper.mapToObjectList(projectInfo.rows);

      if (!projectInfo.externalCustomerId) {
        console.error('Error: No customer id found in the spreadsheet');
        return;
      }

      const customerInfo = await this.getCustomerInfo(projectInfo.externalCustomerId);

      if (!customerInfo) {
        console.error('Error: Could not resolve the customer id');
        return;
      }

      const createdProject = await this.createProject({
        input: {
          customerId: customerInfo.id,
          objectList,
          salesContactUserName: projectInfo.salesContactUserName,
          serviceProviderId: null,
        },
      });
    },
    refetchQueries(): void {
      this.$apollo.queries.projectList.refetch();
    },
    // Clear the state
    clearState() {
      this.$store.commit('clearState');
    },
  },
});
