










































































































import { defineComponent } from '@vue/composition-api';
import FormField from '@/features/ui/form/FormField.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import {
  InfrastructureVariantType,
  IWCloudType,
} from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import { mainServiceOptions } from '@/features/app-enterprise-project/enterprise-project-constants';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { FormField, Flex },
  props: {
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    dirty: {
      type: Boolean,
      required: false,
      default: false,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainServiceOptions: mainServiceOptions,
    };
  },
  computed: {
    infrastructureVariantOptions() {
      return Object.values(InfrastructureVariantType);
    },
    iwCloudOptions() {
      return Object.values(IWCloudType);
    },
    disableIWCloud() {
      return !!this.formData.optionInfrastructure;
    },
  },
  methods: {
    // Function to validate the form
    validateForm(formData: any) {
      return formData.customerName && formData.customerNumber && this.validateEmail(formData.partnerName);
    },
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
  },
});
