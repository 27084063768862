



































































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import { getHeatingSystemsComponentsWithTypes, MetadataStatus } from '@/util/heating-systems';
import {
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import HeatingSystemsMetadataForm from './HeatingSystemsMetadataForm.vue';
import HeatingSystemsMetadataApplySettings from './HeatingSystemsMetadataApplySettings.vue';

export type HeatingSystemMetadataResponse = {
  componentType: string;
  heatingSystemId: string;
  metadata: JSONObject;
};

export default defineComponent({
  components: {
    HeatingSystemsMetadataForm,
    HeatingSystemsMetadataApplySettings,
  },
  props: {
    heatingSystemsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        searchInput: '',
        metadataStatus: null,
      },
      metadataStatusOptions: [
        {
          label: 'Pflichtangaben vollständig',
          value: MetadataStatus.MANDATORY_INFORMATION_COMPLETE,
        },
        {
          label: 'Pflichtangaben unvollständig',
          value: MetadataStatus.MANDATORY_INFORMATION_INCOMPLETE,
        },
      ],
      heatingSystemsMetadata: [] as HeatingSystemMetadataResponse[] | null,
    };
  },
  computed: {
    ...mapGetters(['openModal', 'openApplySettings']),
    heatingSystems(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
    filteredHeatingSystems(): HeatingSystem[] {
      if (this.filters.searchInput) {
        const result = this.heatingSystems.filter((heatingSystem: HeatingSystem) =>
          heatingSystem.street?.toLocaleLowerCase().includes(this.filters.searchInput.toLocaleLowerCase()),
        );
        return result.length > 0 ? result : this.heatingSystems;
      } else if (this.filters.metadataStatus) {
        return this.filterHeatingSystemsByMetadataStatus(this.filters.metadataStatus);
      } else {
        return this.heatingSystems;
      }
    },
    filteretedComponents(): HeatingSystemComponent[] {
      let components = this.heatingSystemsComponents;
      if (this.filters.searchInput) {
        const result = this.heatingSystemsComponents.filter((component: HeatingSystemComponent) =>
          component.label.toLocaleLowerCase().includes(this.filters.searchInput.toLocaleLowerCase()),
        );
        components = result.length > 0 ? result : this.heatingSystemsComponents;
      }
      return components.sort((a, b) => a.label.localeCompare(b.label));
    },
    formData(): HeatingSystemMetadata {
      return this.$store.getters.formData;
    },
  },
  watch: {
    heatingSystemsData(value) {
      const heatingSystems = value.map((item: HeatingSystemSummary) => {
        return {
          ...item,
          active: false,
        };
      });

      this.$store.commit('setHeatingSystems', heatingSystems);
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('openModal', false);
    },
    async onHeatingSystemChange(id: string) {
      const activeHeatingSystem = this.heatingSystems.find((item) => item.components.id === id);

      // set active heating system
      this.heatingSystems.forEach((heatingSystem) => {
        heatingSystem.active = heatingSystem.components.id === id ? true : false;
      });

      // close "apply settings" section
      this.$store.commit('openApplySettings', false);

      // get active heating system components
      if (activeHeatingSystem) {
        const components = getHeatingSystemsComponentsWithTypes(activeHeatingSystem);
        const componentOptions = Array.from(components, ([key, value]) => ({
          value: key,
          label: value.component,
          active: false,
          type: value.type,
        }));
        this.$store.commit('setHeatingSystemsComponents', componentOptions);
      }

      // fetch updated HS metadata
      this.heatingSystemsMetadata = await this.fetchHeatingSystemsMetadata();
    },
    componentRequiredFields(component: string): string[] {
      switch (component) {
        case 'BOILER':
          return ['condensing'];
        case 'WATER_HEATING':
          return [
            'potable_water_target_temp',
            'potable_water_temperatuer_tolerance',
            'circulation_target_temp',
            'potable_water_temperatuer_tolerance',
            'type_of_pwh',
          ];
        //  no required fields for these
        case 'BUFFER':
        case 'HEATING_CIRCUIT':
        case 'TELE_HEATING':
          return [];
        default:
          return [];
      }
    },
    componentHasMissingMetadata(): boolean {
      return true;
    },
    filterHeatingSystemsByMetadataStatus(status: string): HeatingSystem[] {
      if (this.heatingSystemsMetadata) {
        const completedMetadataIds = this.heatingSystemsMetadata.map((hs) => hs.heatingSystemId);
        if (status === 'MANDATORY_INFORMATION_COMPLETE') {
          return this.heatingSystems.filter((hs) => completedMetadataIds.includes(hs.components.id));
        } else if (status === 'MANDATORY_INFORMATION_INCOMPLETE') {
          return this.heatingSystems.filter((hs) => !completedMetadataIds.includes(hs.components.id));
        } else {
          return this.heatingSystems;
        }
      } else {
        return this.heatingSystems;
      }
    },
    async onHeatingSystemComponentChange(id: string) {
      this.heatingSystemsComponents.forEach((component) => {
        component.active = component.value === id ? true : false;
      });
      // close "apply settings" section
      this.$store.commit('openApplySettings', false);

      // this.fetchMetadata();
    },
    async fetchHeatingSystemsMetadata(): Promise<HeatingSystemMetadataResponse[] | null> {
      try {
        const res = await this.$store.dispatch('getAllHeatingSystemMetadata');
        return res.ok ? await res.json() : null;
      } catch (error) {
        return null;
      }
    },
  },
});
