var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Flex',{staticClass:"flex-comp-column"},[_c('Flex',{staticClass:"input-column"},[_c('div',{staticClass:"input-fieldset"},[_c('fieldset',[_c('legend',[_vm._v("Main Service")]),_c('FormField',{attrs:{"input":"ui-input-select","data-element-id":'app-enterprise-project-create-customer-name',"path":"mainService","reduce":function (ref) {
                var value = ref.value;

                return value;
},"options":_vm.mainServiceOptions,"error":_vm.dirty && !_vm.formData.mainService,"description":_vm.dirty && !_vm.formData.mainService ? 'Bitte geben Sie einen mainService ein.' : ''}})],1)])]),_c('Flex',{staticClass:"input-column-2"},[_c('div',{staticClass:"input-fieldset"},[_c('fieldset',[_c('legend',[_vm._v("Infrastrukturvariante")]),_c('FormField',{attrs:{"input":"ui-input-select","data-element-id":'app-enterprise-project-create-customer-name',"path":"optionInfrastructure","options":_vm.infrastructureVariantOptions,"error":_vm.dirty && !_vm.formData.optionInfrastructure,"description":_vm.dirty && !_vm.formData.optionInfrastructure ? 'Bitte geben Sie einen optionInfrastructure ein.' : ''}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-customer-name',"label":'Monatspreis in EUR',"path":"monthlyPriceInEurosInfrastructure","error":_vm.dirty && !_vm.formData.monthlyPriceInEurosInfrastructure,"description":_vm.dirty && !_vm.formData.monthlyPriceInEurosInfrastructure
                ? 'Bitte geben Sie einen monthlyPriceInEurosInfrastructure ein.'
                : ''}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-customer-name',"label":'Einmalpreis in EUR',"path":"oneTimePriceInEurosInfrastructure","error":_vm.dirty && !_vm.formData.oneTimePriceInEurosInfrastructure,"description":_vm.dirty && !_vm.formData.oneTimePriceInEurosInfrastructure
                ? 'Bitte geben Sie einen oneTimePriceInEurosInfrastructure ein.'
                : ''}})],1)]),_c('div',{staticClass:"input-fieldset"},[_c('fieldset',[_c('legend',[_vm._v("IW-Cloud")]),_c('FormField',{attrs:{"input":"ui-input-select","data-element-id":'app-enterprise-project-create-customer-name',"path":"optionIWCloud","reduce":function (ref) {
                                var value = ref.value;

                                return value;
},"options":_vm.iwCloudOptions,"error":_vm.dirty && !_vm.formData.optionIWCloud,"description":_vm.dirty && !_vm.formData.optionIWCloud ? 'Bitte geben Sie einen optionIWCloud ein.' : ''}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-customer-name',"label":'Monatspreis in EUR',"path":"monthlyPriceInEurosIWCloud","error":!!(_vm.dirty && !_vm.formData.monthlyPriceInEurosIWCloud && !_vm.disableIWCloud),"disabled":_vm.disableIWCloud,"use-price-format":true,"description":!!(_vm.dirty && !_vm.formData.monthlyPriceInEurosIWCloud && !_vm.disableIWCloud)
                ? 'Bitte geben Sie einen monthlyPriceInEurosIWCloud ein.'
                : ''}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-customer-name',"label":'Einmalpreis in EUR',"path":"oneTimePriceInEurosIWCloud","error":!!(_vm.dirty && !_vm.formData.oneTimePriceInEurosIWCloud && !_vm.disableIWCloud),"disabled":_vm.disableIWCloud,"use-price-format":true,"description":!!(_vm.dirty && !_vm.formData.oneTimePriceInEurosIWCloud && !_vm.disableIWCloud)
                ? 'Bitte geben Sie einen monthlyPriceInEurosIWCloud ein.'
                : ''}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }