























































































import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions } from 'vuex';
import Form from '@/features/ui/form/Form.global.vue';
import FormField from '@/features/ui/form/FormField.global.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { AddNewEnterpriseProject, AddNewEnterpriseProjectVariables } from './__generated__/AddNewEnterpriseProject';
import createProjectMutation from './add-project.gql';
import customerQuery from '../../../enterprise-project/customer-by-external-id.gql';
import {
  AppEnterpriseProjectCustomerByExternalIdQuery,
  AppEnterpriseProjectCustomerByExternalIdQueryVariables,
} from '../../../enterprise-project/__generated__/AppEnterpriseProjectCustomerByExternalIdQuery';
import { ApolloQueryResult } from 'apollo-client';
import getCustomerByName from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/getCustomerByName.gql';
import { GetCustomerByNameVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/__generated__/GetCustomerByName';
import InputText from '@/features/ui/inputs/InputText.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { InputText, Form, FormField, WizardButton },
  props: {},
  data() {
    return {
      debounceTimer: null, // Timer for the debounce function
      loadedExternalId: null, // The external id of the customer that was loaded on the name match
      searching: false,
    };
  },
  computed: {
    ...mapState({
      generalInformationCompleted: (state: any) => state.EnterpriseProjectCreateModule.generalInformationCompleted,
      generalInformationFormDisabled: (state: any) =>
        state.EnterpriseProjectCreateModule.generalInformationCompleted &&
        state.EnterpriseProjectCreateModule.numberOfNonEditableBuildings > 0,
      customerName: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.customerName,
      customerNumber: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.customerNumber,
      partnerName: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.partnerName,
      invoiceNumber: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.invoiceNumber,
      contractTerm: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.contractTerm,
    }),
    contractTermOptions() {
      const maxYear = 10; // Define the maximum number of years
      const options = [];
      for (let i = 1; i <= maxYear; i++) {
        options.push({
          value: i,
          label: `${i} Jahr${i === 1 ? '' : 'e'}`,
        });
      }
      return options;
    },
  },
  methods: {
    async updateProjectData(formData: any) {
      const result = await (
        this.$apollo.query({
          query: customerQuery,
          variables: {
            externalId: this.loadedExternalId ? this.loadedExternalId : formData.customerNumber,
          } as AppEnterpriseProjectCustomerByExternalIdQueryVariables,
        }) as Promise<ApolloQueryResult<AppEnterpriseProjectCustomerByExternalIdQuery>>
      ).catch((error) => {
        // TODO: Add Proper error handling with a toast and sending to a bug tracker service
        // eslint-disable-next-line no-console
        console.error('An error occured while fetching customer info from the file import:', error);
      });

      const customerData = result?.data?.customers?.first;

      // Call mutation to persist project to database
      const { data } = await this.$apollo.mutate({
        mutation: createProjectMutation,
        variables: {
          input: {
            customerId: customerData?.id,
            objectList: [],
            salesContactUserName: formData.partnerName ?? '',
            serviceProviderId: null,
          },
        } as AddNewEnterpriseProjectVariables,
      });
      const { createProject } = data as AddNewEnterpriseProject;
      formData.id = createProject.project.id;
      formData.projectReference = createProject.project.projectReference;
      formData.general = {
        customerName: formData.customerName,
        customerNumber: this.loadedExternalId ? this.loadedExternalId : formData.customerNumber,
        partnerName: formData.partnerName,
        invoiceNumber: formData.invoiceNumber,
        contractTerm: formData.contractTerm,
      };
      await this.$store.dispatch('saveNewProjectData', formData);
      await this.$store.dispatch('setGeneralInformationCompleted', true);
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
    },
    // Function to validate the form
    validateForm(formData: any) {
      if (!formData.customerName || !this.validateEmail(formData.partnerName) || !formData.contractTerm) {
        return false;
      }
      if (this.loadedExternalId && !formData.invoiceNumber) {
        return false;
      }
      return true;
    },
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
    getInvoiceNumberDescription(dirty: boolean, formData: any) {
      return dirty && !formData.invoiceNumber ? 'Bitte geben Sie einen Rechnungskontonummer ein.' : '';
    },
    async onCustomerNameChange(customerName: string) {
      // Clear the previous timer if the user is still typing
      clearTimeout(this.debounceTimer);

      // Set a new timer to update the query after 1000ms (or your desired delay)
      this.debounceTimer = setTimeout(() => {
        if (this.searching) {
          return;
        }

        this.loadMatchingCustomers(customerName);
      }, 1000); // 1000ms delay
    },
    async loadMatchingCustomers(customerName: string) {
      this.searching = true;
      try {
        // Check if a customer exist with this name
        const { data } = await this.$apollo.query({
          query: getCustomerByName,
          variables: {
            customerName: customerName,
          } as GetCustomerByNameVariables,
        });
        if (data && data.customers.first && data.customers.first.name === customerName) {
          this.loadedExternalId = data.customers.first.externalId;
        } else {
          this.loadedExternalId = null;
        }
      } catch (e) {
        console.error('Error while fetching customer by name', e);
      }
      this.searching = false;
    },
  },
});
