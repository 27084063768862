import { Worksheet } from 'exceljs';
import {
  HEADER_2_STYLE,
  HEADER_STYLE,
  PRODUCT_COLUMN_NAME_MAP,
  USE_CASE_COLUMN_NAME_MAP,
} from './standortlists-constants';
import { EnterpriseProjectObjectUseCase } from '@/features/app-enterprise-project/enterprise-project-constants';
import { EnterpriseProjectObjectProduct } from '@/types/iot-portal';

export interface StyleOptions {
  enabledUseCases: Set<EnterpriseProjectObjectUseCase>;
  enabledProducts: Set<EnterpriseProjectObjectProduct>;
}

function stylePreambleRows(worksheet: Worksheet): void {
  // Preamble Rows
  worksheet.getRow(1).getCell(1).font = { bold: true, size: 16, name: 'Arial Narrow', family: 2 };
  worksheet.getRow(1).getCell(2).font = { bold: true, size: 16, name: 'Arial Narrow', family: 2 };
  worksheet.getRow(2).getCell(1).font = { bold: true, size: 16, name: 'Arial Narrow', family: 2 };
  [3, 4, 5, 6, 7].forEach((row) => {
    worksheet.getRow(row).getCell(1).font = { bold: true, size: 12, name: 'Arial Narrow', family: 2 };
  });

  [3, 4, 5, 6, 8].forEach((row) => {
    worksheet.getRow(row).getCell(3).font = { bold: false, size: 12, name: 'Arial Narrow', family: 2 };
  });

  worksheet.getRow(2).height = 22.5;
  worksheet.getRow(9).height = 64;
  worksheet.getRow(10).height = 43;
}

function styleTableHeaders(worksheet: Worksheet): void {
  let row = worksheet.getRow(9);
  for (let j = 2; j <= row.cellCount; j++) {
    const cell = row.getCell(j);
    cell.style = HEADER_STYLE;
  }

  row = worksheet.getRow(10);
  for (let j = 2; j <= row.cellCount; j++) {
    const cell = row.getCell(j);
    cell.style = HEADER_2_STYLE;
  }

  for (let i = 11; i <= worksheet.rowCount; i++) {
    const row = worksheet.getRow(i);
    for (let j = 1; j <= row.cellCount; j++) {
      const cell = row.getCell(j);
      cell.style = {
        font: { name: 'Arial Narrow', family: 2 },
        border: {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        },
      };
    }
  }
}

export function styleGk4NullSheet(worksheet: Worksheet, styleOptions: StyleOptions): void {
  stylePreambleRows(worksheet);

  worksheet.mergeCells('D9:G9');
  worksheet.mergeCells('M9:O9');
  worksheet.mergeCells('R9:T9');
  worksheet.mergeCells('V9:W9');
  worksheet.mergeCells('X9:Y9');
  worksheet.mergeCells('Z9:AB9');
  worksheet.mergeCells('AC9:AE9');
  worksheet.mergeCells('AF9:AH9');
  worksheet.mergeCells('AI9:AK9');
  worksheet.mergeCells('AL9:AN9');
  worksheet.mergeCells('AO9:AQ9');
  worksheet.mergeCells('AR9:AT9');
  worksheet.mergeCells('AU9:AW9');
  worksheet.mergeCells('AX9:AZ9');
  worksheet.mergeCells('BA9:BC9');
  worksheet.mergeCells('BD9:BF9');
  worksheet.mergeCells('BG9:BI9');

  styleTableHeaders(worksheet);

  for (let i = 11; i <= worksheet.rowCount; i++) {
    const row = worksheet.getRow(i);
    let cell = row.getCell(3);
    cell.border.left = { style: 'double', color: { argb: '00000000' } };
    cell = row.getCell(17);
    cell.border.right = { style: 'double', color: { argb: '00000000' } };
  }

  const lastRow = worksheet.getRow(worksheet.rowCount);
  [3, 4, 5, 6, 7, 9, 11, 12, 13, 17].forEach((column) => {
    lastRow.getCell(column).border.bottom = { style: 'double', color: { argb: '00000000' } };
  });

  for (let i = 11; i <= worksheet.rowCount; i++) {
    const row = worksheet.getRow(i);
    [
      6, 7, 19, 20, 25, 27, 28, 30, 31, 33, 34, 36, 37, 39, 40, 42, 43, 45, 46, 48, 49, 51, 52, 54, 55, 57, 58, 60, 61,
    ].forEach((column) => {
      const cell = row.getCell(column);
      cell.numFmt = '#,##0.00';
    });
  }

  worksheet.autoFilter = {
    from: {
      row: 9,
      column: 2,
    },
    to: {
      row: worksheet.rowCount,
      column: worksheet.columnCount,
    },
  };

  worksheet.addConditionalFormatting({
    ref: `$A11:D${worksheet.rowCount}`,
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: ['$A11="Liegenschaft (Hauptgebäude)"'],
        style: {
          font: {
            bold: true,
          },
        },
      },
    ],
  });

  worksheet.addConditionalFormatting({
    ref: `J11:J${worksheet.rowCount}`,
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: ['$A11="Liegenschaft (Hauptgebäude)"'],
        style: {
          font: {
            bold: true,
          },
        },
      },
    ],
  });

  worksheet.addConditionalFormatting({
    ref: `L11:L${worksheet.rowCount}`,
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: ['$A11="Liegenschaft (Hauptgebäude)"'],
        style: {
          font: {
            bold: true,
          },
        },
      },
    ],
  });

  worksheet.addConditionalFormatting({
    ref: `I11:I${worksheet.rowCount}`,
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: ['$A11="Liegenschaft (Hauptgebäude)"'],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FF6D6D6D' },
          },
        },
      },
    ],
  });

  // Column width formatting
  worksheet.getColumn('C').width = 75;

  worksheet.getColumn('D').width = 40;

  worksheet.getColumn('H').width = 75;

  worksheet.getColumn('I').width = 75;

  worksheet.getColumn('M').width = 40;

  worksheet.getColumn('O').width = 60;

  // Hide use case columns if they are not on the enabled set of use cases

  for (const [useCaseType, columns] of USE_CASE_COLUMN_NAME_MAP.entries()) {
    if (!styleOptions.enabledUseCases.has(useCaseType)) {
      // Hide the columns associated with the missing use case
      for (const column of columns) {
        worksheet.getColumn(column).hidden = true;
      }
    }
  }

  // Hide product columns if they are not on the enabled set of products

  for (const [productType, columns] of PRODUCT_COLUMN_NAME_MAP.entries()) {
    if (!styleOptions.enabledProducts.has(productType)) {
      // Hide the columns associated with the missing product
      for (const column of columns) {
        worksheet.getColumn(column).hidden = true;
      }
    }
  }
}

export function styleBicarbSheet(worksheet: Worksheet): void {
  stylePreambleRows(worksheet);

  worksheet.mergeCells('F9:H9');

  styleTableHeaders(worksheet);

  let cell = worksheet.getRow(10).getCell(7);
  cell.style = {
    ...cell.style,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
  };

  cell = worksheet.getRow(10).getCell(8);
  cell.style = {
    ...cell.style,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
  };

  // Column width formatting
  worksheet.getColumn('B').width = 13;

  worksheet.getColumn('C').width = 75;

  worksheet.getColumn('D').width = 75;
}

const borderWidth = 0.83;
export function setGk40Columns(worksheet: Worksheet): void {
  worksheet.columns = [
    { key: 'A', width: 25.67 + borderWidth },
    { key: 'B', width: 13 + borderWidth },
    { key: 'C', width: 31 + borderWidth },
    { key: 'D', width: 21.17 + borderWidth },
    { key: 'E', width: 8.5 + borderWidth },
    { key: 'F', width: 6.17 + borderWidth },
    { key: 'G', width: 6.5 + borderWidth },
    { key: 'H', width: 44 + borderWidth },
    { key: 'I', width: 37.5 + borderWidth },
    { key: 'J', width: 8.5 + borderWidth },
    { key: 'K', width: 11.67 + borderWidth },
    { key: 'L', width: 6.33 + borderWidth },
    { key: 'M', width: 17 + borderWidth },
    { key: 'N', width: 19 + borderWidth },
    { key: 'O', width: 18.75 + borderWidth },
    { key: 'P', width: 13 + borderWidth },
    { key: 'Q', width: 15.5 + borderWidth },
    { key: 'R', width: 7 + borderWidth },
    { key: 'S', width: 6.17 + borderWidth },
    { key: 'T', width: 6.17 + borderWidth },
    { key: 'U', width: 11.5 + borderWidth },
    { key: 'V', width: 3.67 + borderWidth },
    { key: 'W', width: 6.17 + borderWidth },
    { key: 'X', width: 5.67 + borderWidth },
    { key: 'Y', width: 6.17 + borderWidth },

    { key: 'Z', width: 3.67 + borderWidth },
    { key: 'AA', width: 6.17 + borderWidth },
    { key: 'AB', width: 6.17 + borderWidth },

    { key: 'AC', width: 3.67 + borderWidth },
    { key: 'AD', width: 6.17 + borderWidth },
    { key: 'AE', width: 6.17 + borderWidth },

    { key: 'AF', width: 3.67 + borderWidth },
    { key: 'AG', width: 6.17 + borderWidth },
    { key: 'AH', width: 6.17 + borderWidth },

    { key: 'AI', width: 3.67 + borderWidth },
    { key: 'AJ', width: 6.17 + borderWidth },
    { key: 'AK', width: 6.17 + borderWidth },

    { key: 'AL', width: 3.67 + borderWidth },
    { key: 'AM', width: 6.17 + borderWidth },
    { key: 'AN', width: 6.17 + borderWidth },

    { key: 'AO', width: 3.67 + borderWidth },
    { key: 'AP', width: 6.17 + borderWidth },
    { key: 'AQ', width: 6.17 + borderWidth },

    { key: 'AR', width: 3.67 + borderWidth },
    { key: 'AS', width: 6.17 + borderWidth },
    { key: 'AT', width: 6.17 + borderWidth },

    { key: 'AU', width: 3.67 + borderWidth },
    { key: 'AV', width: 6.17 + borderWidth },
    { key: 'AW', width: 6.17 + borderWidth },

    { key: 'AX', width: 3.67 + borderWidth },
    { key: 'AY', width: 6.17 + borderWidth },
    { key: 'AZ', width: 6.17 + borderWidth },

    { key: 'BA', width: 3.67 + borderWidth },
    { key: 'BB', width: 6.17 + borderWidth },
    { key: 'BC', width: 6.17 + borderWidth },

    { key: 'BD', width: 3.67 + borderWidth },
    { key: 'BE', width: 6.17 + borderWidth },
    { key: 'BF', width: 6.17 + borderWidth },

    { key: 'BG', width: 3.67 + borderWidth },
    { key: 'BH', width: 6.17 + borderWidth },
    { key: 'BI', width: 6.17 + borderWidth },
  ];
}

export function setBicabColumns(worksheet: Worksheet): void {
  worksheet.columns = [
    { key: 'A', width: 24.5 + borderWidth },
    { key: 'B', width: 8.67 + borderWidth },
    { key: 'C', width: 29.33 + borderWidth },
    { key: 'D', width: 29.17 + borderWidth },
    { key: 'E', width: 10 + borderWidth },
    { key: 'F', width: 5.67 + borderWidth },
    { key: 'G', width: 4.83 + borderWidth },
    { key: 'H', width: 5.33 + borderWidth },
    { key: 'I', width: 10 + borderWidth },
    { key: 'J', width: 16.67 + borderWidth },
    { key: 'K', width: 22.83 + borderWidth },
    { key: 'L', width: 22.83 + borderWidth },
  ];
}
