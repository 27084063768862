import ExcelJS from 'exceljs';
import { GetProjectQuery_project } from '../__generated__/GetProjectQuery';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import {
  GetProjectDetailsExportQuery,
  GetProjectDetailsExportQueryVariables,
  GetProjectDetailsExportQuery_projectDetails_items,
  GetProjectDetailsExportQuery_projectDetails_items_customerContacts,
  GetProjectDetailsExportQuery_projectDetails_items_useCaseList,
} from '../__generated__/GetProjectDetailsExportQuery';
import projectDetailExportQuery from '../project-details-export.gql';
import { setBicabColumns, setGk40Columns, styleBicarbSheet, styleGk4NullSheet, StyleOptions } from './style-xlsx';
import {
  BICAB_HEADER_ROW,
  BICAB_HEADER_ROW_2,
  ENTERPRISE_PRODUCT_OPTION_MAP,
  HEADER_ROW,
  HEADER_ROW_2,
  LIEGENSCHAFT_GW_REXEP,
} from './standortlists-constants';
import {
  EnterpriseProjectObjectProduct,
  EnterpriseProjectObjectProductOption,
  EnterpriseProjectObjectUseCase,
  EnterpriseProjectServiceType,
  TreeNodeClassification,
} from '@/types/iot-portal';
import getProjectData from '@/features/app-enterprise-project/views/enterprise-project-create/get-project-draft.gql';
import { GetProjectDataUsingIDQuery } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/GetProjectDataUsingIDQuery';
import {
  DatabaseProjectDraft,
  ProjectDraftCompleted,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import {
  NewProjectData,
  ProjectBuilding,
} from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';

type UseCase = GetProjectDetailsExportQuery_projectDetails_items_useCaseList;
type CustomerContact = GetProjectDetailsExportQuery_projectDetails_items_customerContacts;
type ProjectObjectDetails = GetProjectDetailsExportQuery_projectDetails_items;

interface CustomerInfo {
  customerName: string | null;
  customerNumber: string | null;
  invoiceNumber: string | null;
  contractTerm: number | null;
}

function getExcelProductType(
  object: ProjectObjectDetails,
  option?: EnterpriseProjectObjectProductOption | null,
): string {
  if (option && object.buildingType === TreeNodeClassification.Liegenschaft) {
    return ENTERPRISE_PRODUCT_OPTION_MAP[option];
  }
  return '';
}

function getExcelBuildingType(buildingType: string): string {
  if (buildingType === TreeNodeClassification.Liegenschaft) {
    return 'Liegenschaft (Hauptgebäude)';
  }
  if (buildingType === TreeNodeClassification.Gebaude) {
    return 'Gebäude';
  }
  return '';
}

function formatCustomerContact(customerContact: CustomerContact | undefined): string {
  let name = '';
  if (customerContact?.title) {
    name += `${customerContact.title} `;
  }
  name = name.trimStart();

  if (customerContact?.firstName) {
    name += `${customerContact.firstName} `;
  }
  name = name.trimStart();

  if (customerContact?.surname) {
    name += `${customerContact.surname}`;
  }
  name = name.trim();

  return name;
}

function getUseCaseObject(useCaseList: UseCase[], useCaseType: string): UseCase | undefined {
  return useCaseList?.find((useCase) => useCase.useCaseType === useCaseType);
}

type UseCaseColumns = [string, string | number, string | number];

function getUseCaseColumns(property: ProjectObjectDetails, useCase: UseCase | undefined): UseCaseColumns {
  if (property.buildingType !== TreeNodeClassification.Liegenschaft) {
    return ['', '-', '-'];
  }

  // If there is a use case and at least one price is not null
  if (useCase && (useCase?.oneTimePriceInEuros !== null || useCase?.monthlyPriceInEuros !== null)) {
    return ['ja', useCase.oneTimePriceInEuros ?? '-', useCase.monthlyPriceInEuros ?? '-'];
  }

  return ['nein', '-', '-'];
}

function formatContractTerm(contractTerm: number | null): string {
  const yearDescription = contractTerm == 1 ? 'Jahr' : 'Jahre';
  const formattedContractTerm = contractTerm ? `${contractTerm} ${yearDescription}` : '';

  return formattedContractTerm;
}

function getLocation(property: ProjectObjectDetails): string {
  let location = `${property.streetAddress} ${property.houseAddress}`.trim();
  if (location) {
    location += ', ';
  }
  location += `${property.postCodeAddress} ${property.cityAddress}`.trim();
  return location;
}

function addStandortlisteRow(
  worksheet: ExcelJS.Worksheet,
  property: ProjectObjectDetails,
  gatewayIdentifier: string,
  draftBuilding: ProjectBuilding | null,
  directory: ProjectObjectDetails | null,
): void {
  const generateUseCaseColumns = (useCase?: UseCase): UseCaseColumns => getUseCaseColumns(property, useCase);

  const generatePreInspectionColumn = (preInspection: boolean | undefined | null): string => {
    if (property.buildingType !== TreeNodeClassification.Liegenschaft) {
      return '';
    }

    const selection = preInspection ? 'ja' : 'nein';

    return selection;
  };

  const infrastructureVariant = property.products.find(
    ({ productType }) => productType === EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
  );
  const iwCloud = property.products.find(({ productType }) => productType === EnterpriseProjectObjectProduct.IW_CLOUD);
  const customerContact = property.customerContacts[0];
  const useCaseList = property.useCaseList ?? [];

  const digitizationUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_DIGITIZATION);
  const heatingMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.HEATING_MONITORING);
  const heatingProfessionalUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL);
  const zfaUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.REMOTE_METER_READING);
  const tgaMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_MONITORING);
  const tgaIntegrationUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_INTEGRATION);
  const smgUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.SMG);
  const serviceManagementUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT);
  const vdeUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION);
  const uviUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
  );
  const smokeDetectorMonitoringUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
  );
  const elevatorMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING);
  const smartElevatorMaintenanceUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
  );

  worksheet.addRow([
    getExcelBuildingType(property.buildingType ?? ''),
    property.buildingReferenceId,
    directory?.itemName,
    property.mainService,
    gatewayIdentifier,
    infrastructureVariant?.oneTimePriceInEuros,
    infrastructureVariant?.monthlyPriceInEuros,
    property.buildingType === TreeNodeClassification.Liegenschaft ? getLocation(property) : '',
    property.buildingType === TreeNodeClassification.Gebaude ? getLocation(property) : '',
    getExcelProductType(property, infrastructureVariant?.option),
    property.wieNumber ?? '',
    '',
    formatCustomerContact(customerContact),
    customerContact?.phoneNumber,
    customerContact?.email,
    draftBuilding?.installationStartDate ? draftBuilding.installationStartDate : '',
    draftBuilding?.invoiceName ? draftBuilding.invoiceName : '',
    getExcelProductType(property, iwCloud?.option),
    iwCloud?.oneTimePriceInEuros ?? '-',
    iwCloud?.monthlyPriceInEuros ?? '-',
    draftBuilding?.serviceLevel ? draftBuilding.serviceLevel : '',
    generatePreInspectionColumn(draftBuilding?.preInspection),
    '-',
    ...generateUseCaseColumns(digitizationUseCase).slice(0, -1),
    ...generateUseCaseColumns(heatingMonitoringUseCase),
    ...generateUseCaseColumns(heatingProfessionalUseCase),
    ...generateUseCaseColumns(tgaMonitoringUseCase),
    ...generateUseCaseColumns(tgaIntegrationUseCase),
    ...generateUseCaseColumns(zfaUseCase),
    ...generateUseCaseColumns(smgUseCase),
    ...generateUseCaseColumns(serviceManagementUseCase),
    ...generateUseCaseColumns(vdeUseCase),
    ...generateUseCaseColumns(uviUseCase),
    ...generateUseCaseColumns(smokeDetectorMonitoringUseCase),
    ...generateUseCaseColumns(elevatorMonitoringUseCase),
    ...generateUseCaseColumns(smartElevatorMaintenanceUseCase),
  ]);
}

function getDraftBuildingByBuildingId(
  projectDraft: NewProjectData & ProjectDraftCompleted,
  buildingId: string,
): ProjectBuilding | null {
  const building = projectDraft.buildings.find((building) => Number(building.buildingId) === Number(buildingId));
  if (building) {
    return building;
  }
  return null;
}

function addPropertyServicesInfo(
  worksheet: ExcelJS.Worksheet,
  details: ProjectObjectDetails[],
  projectDraft: NewProjectData & ProjectDraftCompleted,
): void {
  worksheet.addRow(HEADER_ROW);
  worksheet.addRow(HEADER_ROW_2);

  for (const property of details.filter((item) => item.buildingType === TreeNodeClassification.Liegenschaft)) {
    const allGatewayDetails =
      property.gatewayDetails?.filter((item) => LIEGENSCHAFT_GW_REXEP.exec(item.identifier)) || [];
    let gatewayIdentifier = '';

    if (allGatewayDetails.length === 0) {
      if (property.gatewayDetails?.length) {
        gatewayIdentifier = property.gatewayDetails.map((item) => item.identifier).join(' / ');
      }
    } else {
      gatewayIdentifier = allGatewayDetails.map((item) => item.identifier).join(' / ');
    }

    // Get the property's directory
    const directory =
      details.find(
        (item) => item.objectType == 'Directory' && item.buildingReferenceId == property.buildingReferenceId,
      ) ?? null;

    // Get the draft building by buildingId
    if (property.draftBuildingId !== null) {
      const draftBuilding: ProjectBuilding | null = getDraftBuildingByBuildingId(
        projectDraft,
        property.draftBuildingId,
      );
      addStandortlisteRow(worksheet, property, gatewayIdentifier, draftBuilding, directory);
    }

    for (const subProperty of details.filter(
      (item) =>
        item.buildingType === TreeNodeClassification.Gebaude &&
        item.buildingReferenceId === property.buildingReferenceId,
    )) {
      // There should only be one gateway for each Gebäude
      let subGatewayIdentifier = '';

      if (subProperty.gatewayDetails?.length) {
        subGatewayIdentifier = subProperty.gatewayDetails.map((item) => item.identifier).join(' / ');
      }

      addStandortlisteRow(worksheet, subProperty, subGatewayIdentifier, null, null);
    }
  }
}

function addBicab2Rows(worksheet: ExcelJS.Worksheet, details: ProjectObjectDetails[]): void {
  worksheet.addRow(BICAB_HEADER_ROW);
  worksheet.addRow(BICAB_HEADER_ROW_2);

  details.forEach((property) => {
    worksheet.addRow([
      getExcelBuildingType(property.buildingType ?? ''),
      property.buildingReferenceId,
      property.itemName,
      getLocation(property),
      'BICAB 2.0',
      '300/50',
      0,
      0,
      'VF-Station	',
      'Standard Advanced',
      'Absprache Techniker-Kunde	',
      'CPE im Schrank',
    ]);
  });
}

function getStyleOptions(details: ProjectObjectDetails[]): StyleOptions {
  const enabledUseCases = new Set<EnterpriseProjectObjectUseCase>();
  const enabledProducts = new Set<EnterpriseProjectObjectProduct>();

  for (const item of details) {
    for (const useCase of item.useCaseList) {
      if (!enabledUseCases.has(useCase.useCaseType)) {
        enabledUseCases.add(useCase.useCaseType);
      }
    }

    for (const product of item.products) {
      if (product.productType && !enabledProducts.has(product.productType)) {
        enabledProducts.add(product.productType);
      }
    }
  }

  return { enabledUseCases, enabledProducts };
}

async function queryAllProjectDetailsData(
  client: ApolloClient<unknown>,
  variables: Omit<GetProjectDetailsExportQueryVariables, 'skip' | 'take'>,
): Promise<ProjectObjectDetails[]> {
  let allDetails: ProjectObjectDetails[] = [];
  let details: ApolloQueryResult<GetProjectDetailsExportQuery>;
  let skip = 0;

  do {
    details = await client.query<GetProjectDetailsExportQuery>({
      query: projectDetailExportQuery,
      variables: { ...variables, skip, take: 500 },
    });

    allDetails = allDetails.concat(details.data.projectDetails.items);
    skip += 500;
  } while (details.data.projectDetails.count > allDetails.length);

  return allDetails;
}

async function queryProjectDraftCustomerInfo(
  client: ApolloClient<unknown>,
  projectReference: string,
): Promise<CustomerInfo> {
  const { data } = await client.query<GetProjectDataUsingIDQuery>({
    query: getProjectData,
    variables: { projectReference },
  });

  if (!data?.projectDraft?.pageContent) {
    return { customerName: null, customerNumber: null, invoiceNumber: null, contractTerm: null };
  }

  const projectDraft = data.projectDraft?.pageContent as unknown as DatabaseProjectDraft;
  // Get the customer info from the project draft
  const customerName = projectDraft.general.customerName;
  const customerNumber = projectDraft.general.customerNumber;
  const invoiceNumber = projectDraft.general.invoiceNumber;
  const contractTerm = projectDraft.general.contractTerm;
  return { customerName, customerNumber, invoiceNumber, contractTerm };
}

export async function generateStandortlist(
  project: GetProjectQuery_project,
  client: ApolloClient<unknown>,
  projectDraft: NewProjectData & ProjectDraftCompleted,
): Promise<void> {
  const details = await queryAllProjectDetailsData(client, { projectReference: project.projectReference });
  // Get the customer info from the project draft
  const customerInfo = await queryProjectDraftCustomerInfo(client, project.projectReference);
  const customerNumber = customerInfo.customerNumber ?? 'Customer not found';
  const invoiceNumber = customerInfo.invoiceNumber ?? '';
  const contractTerm = formatContractTerm(customerInfo.contractTerm);
  const preamble = [
    ['Standortliste Gk4.0'],
    ['Neubestellung / Änderung'],
    ['Kundenname:', '', customerInfo.customerName ?? 'NO CUSTOMER'],
    ['Kundennummer:', '', customerNumber],
    ['Rechnungskontonummer', '', invoiceNumber],
    ['Vertragslaufzeit', '', contractTerm],
    ['ASP Vertrieb', '', project.salesContactUserName],
    [],
  ];
  const bicabPreamble = preamble.map((row) => [...row]);
  bicabPreamble[0].push('Kabel-Access für GK4.0');
  bicabPreamble[1][0] = 'Neubestellung';

  const workbook = new ExcelJS.Workbook();
  const gk40Worksheet = workbook.addWorksheet('GK4.0', {
    views: [{ state: 'frozen', xSplit: 3, ySplit: 9, showGridLines: false }],
  });

  setGk40Columns(gk40Worksheet);
  gk40Worksheet.addRows(preamble);
  addPropertyServicesInfo(gk40Worksheet, details, projectDraft);
  const styleOptions = getStyleOptions(details);

  styleGk4NullSheet(gk40Worksheet, styleOptions);
  await gk40Worksheet.protect(atob('Z2s0bnVsbCEyMDI0JA=='), {});

  const bicabDetails = keepBicabRows(details);

  if (bicabDetails.length > 0) {
    // only add bicab worksheet if there's bicab related details to add
    const bicabWorksheet = workbook.addWorksheet('BICAB 2.0 für GK4.0', {
      views: [{ state: 'frozen', xSplit: 3, ySplit: 9, showGridLines: false, zoomScale: 145 }],
    });

    setBicabColumns(bicabWorksheet);
    bicabWorksheet.addRows(bicabPreamble);
    addBicab2Rows(bicabWorksheet, bicabDetails);
    styleBicarbSheet(bicabWorksheet);
    await bicabWorksheet.protect(btoa('Z2s0bnVsbCEyMDI0JA=='), {});
  }

  // Code adapted from https://stackoverflow.com/a/74728655 and https://github.com/exceljs/exceljs/issues/402#issuecomment-347927095
  const workbookBuffer = await workbook.xlsx.writeBuffer({ base64: true } as unknown as ExcelJS.XlsxWriteOptions);

  const downloadBlob = new Blob([workbookBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = URL.createObjectURL(downloadBlob);
  link.download = 'GK4.0_Standortliste.xlsx';
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  }, 0);
}

function keepBicabRows(details: ProjectObjectDetails[]): ProjectObjectDetails[] {
  const bicabRows: ProjectObjectDetails[] = details.filter(
    (item) =>
      item.buildingType === TreeNodeClassification.Liegenschaft &&
      item.mainService === EnterpriseProjectServiceType.GK_HUB_L_CABLE,
  );

  return bicabRows;
}
