



















































































































































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState, mapGetters, mapActions } from 'vuex';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import PropertyList from '../step2/PropertyList.vue';
import InputField from '@/features/ui/inputs/InputField.global.vue';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import {
  EnterpriseProjectObjectProduct,
  InfrastructureVariantType,
  IWCloudType,
  LiegenschaftProduct,
  LiegenschaftUseCase,
  ProjectBuilding,
} from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import { Option } from '@/features/ui/inputs/model';
import { kebabCase } from 'lodash';
import {
  aufzugUseCaseOptions,
  EnterpriseProjectObjectUseCase,
  EnterpriseProjectServiceType,
  heizungUseCaseOptions,
} from '@/features/app-enterprise-project/enterprise-project-constants';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: {
    Panel,
    Form,
    WizardButton,
    Cell,
    Flex,
    PropertyList,
    InputField,
    InputText,
    InputCheckbox,
    InputSelect,
  },
  data() {
    return {
      selectedBuildings: [],
      openSelectLiegenschaften: false,
      searchProperty: null,
      openModal: false,
      saving: false,
      showUnselectedBuildings: true,
    };
  },
  computed: {
    ...mapGetters(['activeBuilding', 'liegenschaftenWithEmptyConfigurations']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      projectReference: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.projectReference,
    }),
    liegenshaftBuildings() {
      return this.buildings.filter((building: any) => building.liegenshaft);
    },
    unselectedBuildings() {
      if (this.searchProperty) {
        return this.liegenshaftBuildings.filter(
          (building: ProjectBuilding) =>
            building.id !== this.activeBuilding.id && building.name.includes(this.searchProperty),
        );
      }
      return this.liegenshaftBuildings.filter((building: any) => building.id !== this.activeBuilding.id);
    },
    infrastructureVariantOptions() {
      return Object.values(InfrastructureVariantType);
    },
    iwCloudOptions() {
      return Object.values(IWCloudType);
    },
    disableIWCloud() {
      const infrastructureVariant = this.activeBuilding.products.find(
        (product: LiegenschaftProduct) => product.productType === 'INFRASTRUCTURE_VARIANT',
      );
      const iwCloud = this.activeBuilding.products.find(
        (product: LiegenschaftProduct) => product.productType === 'IW_CLOUD',
      );

      if (infrastructureVariant?.option) {
        iwCloud.monthlyPriceInEuros = null;
        iwCloud.oneTimePriceInEuros = null;
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    slug(value: string): string {
      return kebabCase(value);
    },
    onInputSelectChange(value: string | null, useCase: LiegenschaftUseCase) {
      if (!value) {
        useCase.monthlyPriceInEuros = null;
        useCase.oneTimePriceInEuros = null;
      }
    },
    onCheckboxChange(useCase: LiegenschaftUseCase) {
      if (!useCase.checked) {
        useCase.monthlyPriceInEuros = null;
        useCase.oneTimePriceInEuros = null;
      }
    },
    handleSelectLiegenshaften() {
      this.openSelectLiegenschaften = !this.openSelectLiegenschaften;
    },
    async handleSaveConfigurations() {
      this.saving = true;
      const buildingIds = this.liegenshaftBuildings
        .filter((building: any) => building.checked)
        .map((building: any) => building.buildingId);
      this.$store.commit('applyActiveConfigurations', {
        buildingIds,
        mainService: this.activeBuilding.mainService,
        products: this.activeBuilding.products,
        useCases: this.activeBuilding.useCases,
      });

      // close "Select Liegenschaften" panel
      this.openSelectLiegenschaften = false;

      // go to the next Liegenschaften with no configurations, otherwise go to the first one on the list.
      if (this.liegenschaftenWithEmptyConfigurations.length > 0) {
        const firstBuildingWithEmptyConfigurations: ProjectBuilding = this.liegenschaftenWithEmptyConfigurations[0];
        this.$store.commit('setActiveStep2BuildingId', firstBuildingWithEmptyConfigurations.buildingId);
      } else {
        this.$store.commit('setActiveStep2BuildingId', this.liegenshaftBuildings[0].buildingId);
      }

      // uncheck/reset selected Liegenschaften
      this.liegenshaftBuildings.forEach((building: any) => {
        if (building.checked) {
          building.checked = false;
        }
      });

      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });

      // Show toast message to inform user that the configurations have been applied to the selected Liegenschaften
      await this.addToastMessages({
        messages: [{ text: 'Konfigurationen wurden auf die ausgewählten Liegenschaften angewendet', class: 'success' }],
      });
      this.saving = false;
    },
    async handleAddToAll() {
      this.$store.commit('applyActiveLiegenschaftSettingsToAll', {
        mainService: this.activeBuilding.mainService,
        products: this.activeBuilding.products,
        useCases: this.activeBuilding.useCases,
      });
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
    },
    nextProperty() {
      this.$store.dispatch('getNextActiveBuildingForStep2', this.$apollo);
    },
    async handleConfirmModal() {
      this.saving = true;
      // Save the page content to the DB
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: true });
      await this.addToastMessages({
        messages: [{ text: 'Projekt wurde angelegt', class: 'success' }],
      });
      // Check if we are already on the project details page
      if (this.$route.name !== 'AppEnterpriseProject/ProjectDetails') {
        // Navigate to project details page
        await this.$router.push({
          name: 'AppEnterpriseProject/ProjectDetails',
          params: {
            projectReference: this.projectReference,
          },
        });
      }

      this.openModal = false;
      this.saving = false;
      // Hide modal
      this.$emit('hide');
    },
    inputSelectReduce(option: Option) {
      return option.value;
    },
    getProductTypeHeading(type: EnterpriseProjectObjectProduct) {
      switch (type) {
        case EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT:
          return 'Infrastrukturvariante';
        case EnterpriseProjectObjectProduct.IW_CLOUD:
          return 'IW-Cloud';
        default:
          return null;
      }
    },
    mainServiceOptions() {
      // NB: GK_HUB_G should be excluded on FE
      return [
        {
          label: 'GK_HUB_L_Kabel',
          value: EnterpriseProjectServiceType.GK_HUB_L_CABLE,
        },
        {
          label: 'GK_HUB_L_GDSP_MOBILFUNK',
          value: EnterpriseProjectServiceType.GK_HUB_L_GDSP_MOBILFUNK,
        },
      ];
    },
    heizungUseCaseOptions() {
      return heizungUseCaseOptions;
    },
    aufzugUseCaseOptions() {
      return aufzugUseCaseOptions;
    },
    isApplicationGroup(useCaseType: EnterpriseProjectObjectUseCase) {
      return [
        // heizung
        EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING,
        EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL,
        // aufzug
        EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING,
        EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
      ].includes(useCaseType);
    },
    isTGAGroup(useCaseType: EnterpriseProjectObjectUseCase) {
      return [
        EnterpriseProjectObjectUseCase.TGA_DIGITIZATION,
        EnterpriseProjectObjectUseCase.TGA_MONITORING,
        EnterpriseProjectObjectUseCase.TGA_INTEGRATION,
      ].includes(useCaseType);
    },
    isOtherGroup(useCaseType: EnterpriseProjectObjectUseCase) {
      return [
        EnterpriseProjectObjectUseCase.REMOTE_METER_READING,
        EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT,
        EnterpriseProjectObjectUseCase.SMG,
        EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION,
        EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
        EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
      ].includes(useCaseType);
    },
    selectAllFilteredBuildings() {
      this.showUnselectedBuildings = false;
      this.unselectedBuildings.forEach((building: any) => {
        building.checked = true;
      });
      this.showUnselectedBuildings = true;
    },
    deselectAllFilteredBuildings() {
      this.showUnselectedBuildings = false;
      this.unselectedBuildings.forEach((building: any) => {
        building.checked = false;
      });
      this.showUnselectedBuildings = true;
    },
  },
});
