





























import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import { doesBuildingHaveInfo } from '@/features/app-enterprise-project/views/enterprise-project-create/components/step3/step-3-helpers';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: {
    InputText,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters(['currentTab']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      buildingsCreated: (state: any) => state.EnterpriseProjectCreateModule.buildingsCreated,
      editableBuildings: (state: any) => state.EnterpriseProjectCreateModule.editableBuildings,
      tab: (state: any) => state.EnterpriseProjectCreateModule.tab,
    }),
    liegenshaftBuildings() {
      return this.buildings.filter((building: any) => building.liegenshaft);
    },
    filteredBuildings() {
      return this.liegenshaftBuildings.filter((building: any) =>
        building.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    ...mapActions(['setActiveStep2BuildingId']),
    setActiveBuilding(id: number) {
      this.setActiveStep2BuildingId(id);
    },
    getListItemSubText(building: ProjectBuilding): string {
      if (building.buildingId === this.activeStep2BuildingId) {
        return 'in Bearbeitung';
      }

      if (this.buildingsCreated && !this.editableBuildings.has(`${building.buildingId}`)) {
        // uneditable building in a project where buildings are already created
        return 'nicht editierbar';
      }

      // Check if building is the parent of other buildings
      if (
        this.tab === 'step2' &&
        this.buildings.some((b: ProjectBuilding) => b.parentProperty === building.buildingId)
      ) {
        return 'ändern';
      }

      // If on step 3 we check if the building has the required information
      if (this.tab === 'step3' && doesBuildingHaveInfo(building)) {
        return 'ändern';
      }

      return 'offen';
    },
  },
});
