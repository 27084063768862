

















































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { defineComponent, ref } from '@vue/composition-api';
import GroupsMixin from '@/features/core/components/mixins/groups';
import EnterpriseIcon from '../../assets/app-enterprise-project-alternative.svg?vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import projectQuery from '@/features/app-enterprise-project/views/enterprise-project-details/project.gql';
import query from '@/features/app-enterprise-project/views/enterprise-project-details/project-details.gql';
import TabButton from '@/features/ui/tab-bar/TabButton.global.vue';
import TabBar from '@/features/ui/tab-bar/TabBar.global.vue';
import ProjectAuditLogDraftTab from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/components/ProjectAuditLogDraftTab.vue';
import ProjectItemStatusAuditLogsTab from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/components/ProjectItemStatusAuditLogsTab.vue';
import TableSkeleton from '@/features/ui/table/TableSkeleton.global.vue';
import ProjectAuditLogStandortlisteTab from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/components/ProjectAuditLogStandortlisteTab.vue';

export default defineComponent<Record<string, unknown>, any, any>({
  name: 'ProjectAuditLogsView',
  components: {
    TableSkeleton,
    TabBar,
    TabButton,
    EnterpriseIcon,
    Flex,
    Cell,
    Panel,
    ProjectAuditLogDraftTab,
    ProjectItemStatusAuditLogsTab,
    ProjectAuditLogStandortlisteTab,
  },
  apollo: {
    project: {
      query: projectQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
        };
      },
    },
    projectDetails: {
      query,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
          skip: 0,
          take: 1000,
        };
      },
      result({ data }: any) {
        if (!data) {
          return;
        }

        for (const item of data.projectDetails.items) {
          if (item.externalId) {
            item.pdtpCCRNumber = item.externalId;
          }
          if (item.ccrReferenceId) {
            item.pdtpCCRNumber = item.ccrReferenceId;
          }
        }

        this.projectDetails = data.projectDetails;
      },
    },
  },
  mixins: [PaginationQueryStringMixin, GroupsMixin],
  props: { projectReference: { type: String, required: true } },
  data() {
    return {
      project: undefined,
      projectList: undefined,
      projectDetails: undefined,
      tab: 'tab1',
    };
  },
  computed: {
    loading(): boolean {
      return this.$apollo.loading;
    },
  },
  methods: {
    // Tab
    updateTab(tab: string) {
      this.tab = tab;
    },
  },
});
