





































































import { defineComponent, ref } from '@vue/composition-api';
import TabBar from '@/features/ui/tab-bar/TabBar.global.vue';
import TabButton from '@/features/ui/tab-bar/TabButton.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import { mapActions, mapState } from 'vuex';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import PropertyEditGeneral from '@/features/app-enterprise-project/views/enterprise-project-details/components/PropertyEditGeneral.vue';
import PropertyEditProducts from '@/features/app-enterprise-project/views/enterprise-project-details/components/PropertyEditProducts.vue';
import PropertyEditApplications from '@/features/app-enterprise-project/views/enterprise-project-details/components/PropertyEditApplications.vue';

export default defineComponent({
  components: {
    WizardButton,
    Form,
    Panel,
    Cell,
    TabBar,
    TabButton,
    PropertyEditGeneral,
    PropertyEditProducts,
    PropertyEditApplications,
  },
  props: {},
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      // Clear the state
      emit('clear');
      emit('hide');
    };

    const confirm = (): void => {
      visible.value = false;
      // Clear the state
      emit('clear');
      emit('confirm');
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
  data() {
    return {
      step: 1,
      initialFormData: {},
    };
  },
  computed: {
    ...mapState({
      tab: (state: any) => state.EnterprisePropertyEditModule.tab,
      // General Customer Information
      customerName: (state: any) => state.EnterprisePropertyEditModule.projectDraftData.general.customerName,
      customerNumber: (state: any) => state.EnterprisePropertyEditModule.projectDraftData.general.customerNumber,
      partnerName: (state: any) => state.EnterprisePropertyEditModule.projectDraftData.general.partnerName,
      buildingName: (state: any) => {
        // {{ row.streetAddress }} {{ row.houseAddress }} {{ row.postCodeAddress }} {{ row.cityAddress }} state.EnterprisePropertyEditModule.buildingInformation
        return `${state.EnterprisePropertyEditModule.buildingInformation?.streetAddress} ${state.EnterprisePropertyEditModule.buildingInformation?.houseAddress} ${state.EnterprisePropertyEditModule.buildingInformation?.postCodeAddress} ${state.EnterprisePropertyEditModule.buildingInformation?.cityAddress}`;
      },
    }),
  },
  watch: {
    // Watch the visible from setup
    visible(newValue) {
      if (newValue) {
        // get the initial data from store getter
        this.initialFormData = this.$store.getters.getFormData;
      }
    },
  },
  methods: {
    // Tab
    updateTab(tab: string) {
      this.$store.commit('setPropertyEditTab', tab);
    },
    goToStep(step: string) {
      this.$store.commit('setPropertyEditTab', step);
    },
    updateProperty(data: any) {
      this.$store.dispatch('savePropertyBuilding', {
        formData: data,
        apolloClient: this.$apollo,
      });
      this.confirm();
    },
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
    validForm(formData: any): boolean {
      // Helper function to check if the required fields for a section are valid
      const isSectionValid = (
        checkboxKey: string,
        monthlyPriceKey: string | null,
        oneTimePriceKey: string,
      ): boolean => {
        // If the checkbox is selected, both price fields must be filled
        if (formData[checkboxKey] && monthlyPriceKey) {
          return !!formData[monthlyPriceKey] && !!formData[oneTimePriceKey];
        }
        // If the checkbox is not selected, it's valid regardless of the price fields
        return true;
      };

      // Validate each section
      const isTgaDigitizationValid = isSectionValid('tgaDigitization', null, 'oneTimePriceInEurosTgaDigitization');
      const isTgaMonitoringValid = isSectionValid(
        'tgaMonitoring',
        'monthlyPriceInEurosTgaMonitoring',
        'oneTimePriceInEurosTgaMonitoring',
      );
      const isTgaIntegrationValid = isSectionValid(
        'tgaIntegration',
        'monthlyPriceInEurosTgaIntegration',
        'oneTimePriceInEurosTgaIntegration',
      );

      const isRemoteMeterReadingValid = isSectionValid(
        'remoteMeterReading',
        'monthlyPriceInEurosRemoteMeterReading',
        'oneTimePriceInEurosRemoteMeterReading',
      );
      const isServiceManagementValid = isSectionValid(
        'serviceManagement',
        'monthlyPriceInEurosServiceManagement',
        'oneTimePriceInEurosServiceManagement',
      );
      const isSmgValid = isSectionValid('smg', 'monthlyPriceInEurosSmg', 'oneTimePriceInEurosSmg');

      const isConsumptionDataAcquisitionValid = isSectionValid(
        'consumptionDataAcquisition',
        'monthlyPriceInEurosConsumptionDataAcquisition',
        'oneTimePriceInEurosConsumptionDataAcquisition',
      );
      const isConsumptionInformationDuringTheYearValid = isSectionValid(
        'consumptionInformationDuringTheYear',
        'monthlyPriceInEurosConsumptionInformationDuringTheYear',
        'oneTimePriceInEurosConsumptionInformationDuringTheYear',
      );
      const isSmokeAlarmMonitoringValid = isSectionValid(
        'smokeAlarmMonitoring',
        'monthlyPriceInEurosSmokeAlarmMonitoring',
        'oneTimePriceInEurosSmokeAlarmMonitoring',
      );

      // The form is valid only if all sections are valid
      return (
        isTgaDigitizationValid &&
        isTgaMonitoringValid &&
        isTgaIntegrationValid &&
        isRemoteMeterReadingValid &&
        isServiceManagementValid &&
        isSmgValid &&
        isConsumptionDataAcquisitionValid &&
        isConsumptionInformationDuringTheYearValid &&
        isSmokeAlarmMonitoringValid
      );
    },
  },
});
