

















































































































































import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions } from 'vuex';
import FormField from '@/features/ui/form/FormField.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { FormField, Flex },
  props: {
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    dirty: {
      type: Boolean,
      required: false,
      default: false,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      customerName: (state: any) => state.EnterprisePropertyEditModule.projectDraftData.general.customerName,
      customerNumber: (state: any) => state.EnterprisePropertyEditModule.projectDraftData.general.customerNumber,
      partnerName: (state: any) => state.EnterprisePropertyEditModule.projectDraftData.general.partnerName,
    }),
  },
  methods: {
    // Function to validate the form
    validateForm(formData: any) {
      return formData.customerName && formData.customerNumber && this.validateEmail(formData.partnerName);
    },
    // Form
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
  },
});
